.wrapper {
    header {
        background: white;
        box-shadow: none;
        border-bottom: 1px solid #DEDEDE;
        padding: 3px 15px;

        .MuiIconButton-root {
            color: $azul;
        }

        img {
            width: 250px;
            transition: all 0.3s ease-in-out;
            // &:last-child {
            //     width: 280px;
            // }

            // &:last-child {
            //     @media (max-width: 992px) {
            //         display: none;
            //     }
            // }

            &:hover {
                filter: brightness(1.2);
                cursor: pointer;
            }
        }
    }
}