//colores

$azul-claro: #2272CE;
$azul : #006370;
$azul-oscuro: #00414A;

$verde:#008072;
$verde-oscuro: #00695f;
$verde-claro: #309A80;
$verde-resaltador:#ebfffc;

$rojo: #f50057;



