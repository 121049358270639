//Tipografia
h2,
.MuiTypography-h6 {
    font-weight: 300 !important;
    font-size: 2rem !important;
    color: $azul;
}

.MuiTableSortLabel-root:hover {
    color: white !important;
}

.MuiPaper-elevation2 {
    box-shadow: none !important;
}

.MuiToolbar-regular {
    padding-left: 0 !important;
}

.MuiSelect-select.MuiSelect-select {
    font-size: 0.9rem;
}

//header Tablas
.MuiTableCell-root {
    padding: 2px 4px !important;
    font-size: 14px!important;

    &:first-child {
        border-radius: 4px 0 0 4px;
    }

    &:last-child {
        border-radius: 0 4px 4px 0;
    }
}