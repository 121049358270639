// importo componentes customizados

@import "variables";
@import "layout";
@import "header";
@import "tipografia";


//
body {
  background: $azul-oscuro;
  background: linear-gradient(180deg, $verde-oscuro 0%, $azul-oscuro 95%);
  //height: 100vh;
}

//Tooltip
.MuiTooltip-tooltip {
  letter-spacing: 1px;
  background: #000 !important;
  font-size: 12px !important;
  font-weight: 200;
}

//Labels
.MuiContainer-root {

  .MuiTextField-root label {
    text-transform: uppercase;
    color: $verde;
    padding-right: 5px;
    background-color: white;
  }

  .MuiInput-underline::after {
    border-bottom-color: $verde;
  }

  .MuiButton-containedPrimary {
    background-color: $verde;
  }
  

  //Boton
  .MuiButton-containedPrimary {
    background-color: $verde;

    &:hover {
      background-color: $verde-claro;
    }
  }

  //checkbox
  .MuiCheckbox-colorPrimary:hover,
  .MuiCheckbox-colorPrimary.Mui-checked:hover {
    background-color: $verde-resaltador;
  }

  .MuiCheckbox-colorPrimary.Mui-checked {
    color: $verde-claro;
  }
  //readonly
  input:read-only,
  textarea:read-only{
    color: #AAA;
  }

  //Links
  .MuiTypography-colorPrimary {
    color: $azul;
  }

  //Tabs
  .MuiTab-textColorPrimary {
    color: rgb(130 130 130 / 54%);
  }

  .MuiTab-textColorPrimary.Mui-selected {
    color: $azul;
  }

  span.MuiTabs-indicator {
    background-color: $azul!important;
  }
}
//Texarea outline
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: $verde!important;
}


//Swal2
div.swal2-popup {
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
  max-width: 90%;
  width: 700px;

  .swal2-title {
    padding-top: 5px;

    strong {
      font-weight: 300;
      color: $rojo;
    }
  }

  .swal2-close:focus {
    box-shadow: none!important;
  }

  .swal2-icon.swal2-warning {
    border-color: $rojo;
    color: $rojo;
  }

  .swal2-icon.swal2-success {
    color: $verde-claro;

    .swal2-success-ring {
      border-color: $verde-claro;

    }

    span {
      background-color: $verde-claro !important;
    }
  }

  .swal2-html-container {
    padding-top: 5px;
  }

  .swal2-content {
    padding-left: 4%;
    padding-right: 4%;
  }

  .swal2-actions {
    button.swal2-styled {
      border-radius: 4px;
      box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
      font-size: 14px;
      font-weight: 400;
      height: 36px;
      letter-spacing: .5px;
      line-height: 36px;
      margin: 5px 10px;
      padding: 0 16px;
      text-transform: uppercase;

      &:hover {
        box-shadow: 0 3px 3px 0 rgb(0 0 0 / 14%), 0 1px 7px 0 rgb(0 0 0 / 12%), 0 3px 1px -1px rgb(0 0 0 / 20%);
        background-image: none !important;
      }

      &.swal2-confirm,
      &.swal2-confirm:hover {
        background-color: $rojo;
      }

      &.swal2-cancel,
      &.swal2-cancel:hover {
        background-color: white;
        color: #424242;
      }
    }
  }

  &.swal2-icon-success {
    .swal2-actions {
      button.swal2-styled {

        &.swal2-confirm,
        &.swal2-confirm:hover {
          background: $verde-claro;
        }
      }
    }
  }
}